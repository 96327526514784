import React from 'react';
import { FormattedMessage } from 'react-intl';
import './ConceptSection.css';

function ConceptSection() {
  return (
    <section id="concept">
      <h2>
        <FormattedMessage
          id="concept.title"
          defaultMessage="What's Arigatou Travel Support くん"
        />
      </h2>
      <div className="section-item">
        <p>
          <FormattedMessage
            id="concept.description"
            defaultMessage="Arigatou Travel Support くん is a comprehensive support service designed for foreign tourists traveling in Japan. Our team of native staff from Tokyo, Kyoto, and Osaka leverages their local knowledge and expertise to provide the following services. For instance....."
          />
        </p>
        <ul>
          <li>
            <FormattedMessage
              id="concept.service1"
              defaultMessage="Restaurant and service reservation assistance"
            />
          </li>
          <li>
            <FormattedMessage
              id="concept.service2"
              defaultMessage="Answers to questions that may be difficult to understand without living in Japan for an extended period, even after researching online"
            />
          </li>
          <li>
            <FormattedMessage
              id="concept.service3"
              defaultMessage="Optimized route guidance to destinations not easily found online"
            />
          </li>
          <li>
            <FormattedMessage
              id="concept.service4"
              defaultMessage="Emergency telephone support for incidents, accidents, and medical situations"
            />
          </li>
          <li>
            <FormattedMessage
              id="concept.service5"
              defaultMessage="Luggage arrangement and delivery"
            />
          </li>
          <li>
            <FormattedMessage
              id="concept.service6"
              defaultMessage="Personalized recommendations for tourist attractions based on your interests, such as anime, movie, or drama pilgrimage sites"
            />
          </li>
          <li>
            <FormattedMessage
              id="concept.service7"
              defaultMessage="Online tour guide services"
            />
          </li>
          <li>
            <FormattedMessage
              id="concept.service8"
              defaultMessage="Tailored travel itinerary suggestions that may be challenging to request face-to-face, leveraging the strengths of online communication"
            />
          </li>
          <li>
            <FormattedMessage
              id="concept.service9"
              defaultMessage="Recommendations for delicious, budget-friendly restaurants where locals eat at affordable prices"
            />
          </li>
        </ul>
        <p>
          <FormattedMessage
            id="concept.assistance"
            defaultMessage="Please feel free to reach out to us for any additional assistance, as we are well-equipped to accommodate your specific requirements."
          />
        </p>
      </div>
    </section>
  );
}

export default ConceptSection;
