import React, { useState } from 'react';
import './BusinessInfo.css';

const BusinessInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleInfo = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="business-info">
      <h2 onClick={toggleInfo} className="business-info-header">
        <small>Notations based on the Act on Specified Commercial Transactions(特定商取引法に基づく表記)</small>
        <span className={`toggle-icon ${isOpen ? 'open' : ''}`}></span>
      </h2>
      {isOpen && (
        <table>
          <tr>
            <th>Business operator name</th>
            <td>Kenji Uehara 上原健志</td>
          </tr>
          <tr>
            <th>Address of business operator</th>
            <td>Will be promptly disclosed upon request</td>
          </tr>
          <tr>
            <th>Contact information of business operator</th>
            <td>
              Phone number: Will be promptly disclosed upon request<br />
              Email address: jaspbusiness118@gmail.com
            </td>
          </tr>
          <tr>
            <th>Price of goods/services</th>
            <td>
              Explorer of Japan: ¥3,500<br />
              Master of Japan: ¥5,000
            </td>
          </tr>
          <tr>
            <th>Payment period and method</th>
            <td>Credit card payment, immediate payment upon order confirmation</td>
          </tr>
          <tr>
            <th>Delivery time of goods or provision time of services</th>
            <td>Services are provided immediately after order confirmation</td>
          </tr>
          <tr>
            <th>Conditions related to return, exchange, and cancellation</th>
            <td>
              Due to the nature of digital content, returns, exchanges, and cancellations cannot be accepted after purchase.<br />
              However, if there are significant defects in the service, we will respond accordingly.
            </td>
          </tr>
        </table>
      )}
    </div>
  );
};

export default BusinessInfo;
