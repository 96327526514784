import React from 'react';
import './ContactUs.css';
import BusinessInfo from './BusinessInfo'; // BusinessInfoコンポーネントをインポート

const ContactUs = () => {
  return (
    <section id="contact-us">
      <h2>Contact Us</h2>
      <div className="contact-info">
        <p>
          <strong>Email:</strong>{' '}
          <a href="mailto:jaspbusiness118@gmail.com">jaspbusiness118@gmail.com</a>
        </p>
      </div>
      <BusinessInfo /> {/* BusinessInfoコンポーネントを追加 */}
    </section>
  );
};

export default ContactUs;
