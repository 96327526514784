import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import './Header.css';

function Header({ onLanguageChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const intl = useIntl();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleLanguageMenu = () => {
        setIsLanguageOpen(!isLanguageOpen);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        setIsOpen(false);
    };

    const handleLanguageChange = (selectedLanguage) => {
        onLanguageChange(selectedLanguage);
        setIsLanguageOpen(false);
    };

    return (
        <header>
            <div className="header-container">
                <div className="logo">
                    <h1>Arigatou Travel Support くん</h1>
                    <div className="language-dropdown">
                        <button className="language-dropdown-toggle" onClick={toggleLanguageMenu}>
                            {intl.formatMessage({ id: 'app.switchLanguage' })}
                        </button>
                        <div className={`dropdown-menu ${isLanguageOpen ? 'active' : ''}`}>
                            <button onClick={() => handleLanguageChange('en')}>English</button>
                            <button onClick={() => handleLanguageChange('ja')}>日本語</button>
                            <button onClick={() => handleLanguageChange('ko')}>한국어</button>
                            <button onClick={() => handleLanguageChange('zh-CN')}>简体中文</button>
                            <button onClick={() => handleLanguageChange('zh-TW')}>繁體中文</button>
                            <button onClick={() => handleLanguageChange('fr')}>Français</button>
                        </div>
                    </div>
                </div>
                <nav>
                    <div className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
                        <li><Link to="/" onClick={() => scrollToSection('how-to-use')}>
                            <FormattedMessage id="header.howToUse" defaultMessage="How To Use" />
                        </Link></li>
                        <li><Link to="/" onClick={() => scrollToSection('service-plans-home')}>
                            <FormattedMessage id="header.servicePlans" defaultMessage="Service Plans" />
                        </Link></li>
                        <li><Link to="/" onClick={() => scrollToSection('contact-us')}>
                            <FormattedMessage id="header.contactUs" defaultMessage="Contact Us" />
                        </Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
