import React from 'react';
import { FormattedMessage } from 'react-intl';
import './HowToUse.css';

const HowToUse = () => {
  return (
    <div id="how-to-use" className="how-to-use-container">
      <h2>
        <FormattedMessage
          id="howToUse.title"
          defaultMessage="How to Use Our Service"
        />
      </h2>
      <ol>
        <li>
          <strong>
            <FormattedMessage
              id="howToUse.step1"
              defaultMessage="Register & Login"
            />
          </strong>
        </li>
        <li>
          <strong>
            <FormattedMessage
              id="howToUse.step2"
              defaultMessage="Plan Selection:"
            />
          </strong>
          <FormattedMessage
            id="howToUse.step2Description"
            defaultMessage=" After logging in, you'll be automatically redirected to the service plan selection page. Simply click on your desired plan, and you'll be seamlessly directed to the payment page."
          />
        </li>
        <li>
          <strong>
            <FormattedMessage
              id="howToUse.step3"
              defaultMessage="Payment:"
            />
          </strong>
          <FormattedMessage
            id="howToUse.step3Description"
            defaultMessage=" On the payment page, where you'll be automatically redirected, please enter your details and complete the payment using your credit card or Apple Pay."
          />
        </li>
        <li>
          <strong>
            <FormattedMessage
              id="howToUse.step4"
              defaultMessage="Application Complete:"
            />
          </strong>
          <FormattedMessage
            id="howToUse.step4Description"
            defaultMessage=" Once your payment is processed, your application is complete. We'll contact you via email to discuss the specific start date and time for your service, ensuring that it aligns with your schedule and preferences."
          />
        </li>
      </ol>
      <p>
        <FormattedMessage
          id="howToUse.assistance"
          defaultMessage="Throughout the process, our team is available to assist you with any questions or concerns you may have. We strive to make the registration and payment process as smooth and user-friendly as possible, so you can focus on preparing for your exciting journey to Japan."
        />
      </p>
    </div>
  );
};

export default HowToUse;
