import React from 'react';
import './Footer.css'; // CSSファイルのインポート

const Footer = () => {
  return (
    <footer className="footer">
      © 2024 Arigatou Travel Support くん
    </footer>
  );
}

export default Footer;

