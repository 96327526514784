import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import './ServicePlans.css';

const ServicePlansHome = () => {
  const [exchangeRates, setExchangeRates] = useState({});

  useEffect(() => {
    fetch('https://api.exchangerate-api.com/v4/latest/JPY')
      .then(response => response.json())
      .then(data => setExchangeRates(data.rates));
  }, []);

  const formatCurrency = (amount, rate) => {
    return Math.round(amount * rate);
  };

  return (
    <div id="service-plans-home" className="service-plans-container">
      <h2>
        <FormattedMessage
          id="servicePlansHome.title"
          defaultMessage="Service Plan"
        />
      </h2>
      <div className="plans-container">
        <div className="plan">
          <h3>
            <FormattedMessage
              id="servicePlansHome.explorerOfJapan.title"
              defaultMessage="Explorer of Japan"
            />
          </h3>
          <ul>
            <li>
              <FormattedMessage
                id="servicePlansHome.explorerOfJapan.feature1"
                defaultMessage="Itinerary optimization (one-time)"
              />
            </li>
            <li>
              <FormattedMessage
                id="servicePlansHome.explorerOfJapan.feature2"
                defaultMessage="Concierge service (up to 5 times a day)"
              />
            </li>
            <li>
              <FormattedMessage
                id="servicePlansHome.explorerOfJapan.feature3"
                defaultMessage="Valid for 3 days"
              />
            </li>
          </ul>
          <p>
            <FormattedMessage
              id="servicePlansHome.explorerOfJapan.price"
              defaultMessage="Price: ¥3,500 ({usd} USD, €{eur} EUR, £{gbp} GBP, ¥{cny} CNY, ₩{krw} KRW, NT{twd} TWD, HK{hkd} HKD)"
              values={{
                usd: formatCurrency(3500, exchangeRates.USD),
                eur: formatCurrency(3500, exchangeRates.EUR),
                gbp: formatCurrency(3500, exchangeRates.GBP),
                cny: formatCurrency(3500, exchangeRates.CNY),
                krw: formatCurrency(3500, exchangeRates.KRW),
                twd: formatCurrency(3500, exchangeRates.TWD),
                hkd: formatCurrency(3500, exchangeRates.HKD),
              }}
            />
          </p>
        </div>
        <div className="plan">
          <h3>
            <FormattedMessage
              id="servicePlansHome.masterOfJapan.title"
              defaultMessage="Master of Japan"
            />
          </h3>
          <ul>
            <li>
              <FormattedMessage
                id="servicePlansHome.masterOfJapan.feature1"
                defaultMessage="Itinerary optimization (unlimited)"
              />
            </li>
            <li>
              <FormattedMessage
                id="servicePlansHome.masterOfJapan.feature2"
                defaultMessage="Concierge service (unlimited per day)"
              />
            </li>
            <li>
              <FormattedMessage
                id="servicePlansHome.masterOfJapan.feature3"
                defaultMessage="Valid for 4 days"
              />
            </li>
            <li>
              <FormattedMessage
                id="servicePlansHome.masterOfJapan.feature4"
                defaultMessage="Emergency phone support service"
              />
            </li>
          </ul>
          <p>
            <FormattedMessage
              id="servicePlansHome.masterOfJapan.price"
              defaultMessage="Price: ¥5,000 ({usd} USD, €{eur} EUR, £{gbp} GBP, ¥{cny} CNY, ₩{krw} KRW, NT{twd} TWD, HK{hkd} HKD)"
              values={{
                usd: formatCurrency(5000, exchangeRates.USD),
                eur: formatCurrency(5000, exchangeRates.EUR),
                gbp: formatCurrency(5000, exchangeRates.GBP),
                cny: formatCurrency(5000, exchangeRates.CNY),
                krw: formatCurrency(5000, exchangeRates.KRW),
                twd: formatCurrency(5000, exchangeRates.TWD),
                hkd: formatCurrency(5000, exchangeRates.HKD),
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicePlansHome;
