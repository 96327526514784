import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Splash from './components/Splash';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ConceptSection from './components/ConceptSection';
import ServicePlansHome from './components/ServicePlans/ServicePlansHome';
import ServicePlansPayment from './components/ServicePlans/ServicePlans';
import HowToUse from './components/HowToUse';
import Registration from './components/Auth/Registration';
import Login from './components/Auth/Login';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import enMessages from './i18n/en.json';
import jaMessages from './i18n/ja.json';
import koMessages from './i18n/ko.json';
import zhCNMessages from './i18n/zh-CN.json';
import zhTWMessages from './i18n/zh-TW.json';
import frMessages from './i18n/fr.json';
import './App.css';

const messages = {
  en: enMessages,
  ja: jaMessages,
  ko: koMessages,
  'zh-CN': zhCNMessages,
  'zh-TW': zhTWMessages,
  fr: frMessages,
};

const App = () => {
  const [locale, setLocale] = useState('en');

  const handleLanguageChange = (selectedLocale) => {
    setLocale(selectedLocale);
  };

  return (
    <IntlProvider messages={messages[locale]} locale={locale}>
      <Router>
        <div className="App">
          <Splash />
          <Header onLanguageChange={handleLanguageChange} />
          <Routes>
            <Route path="/" element={
              <>
                <HeroSection />
                <ConceptSection />
                <ServicePlansHome />
                <HowToUse />
                <div className="auth-container">
                  <Registration />
                  <Login />
                </div>
                <ContactUs />
              </>
            } />
            <Route path="/how-to-use" element={<HowToUse />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/service-plans" element={<ServicePlansPayment />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </IntlProvider>
  );
};

export default App;
