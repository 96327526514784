import React, { useEffect, useState } from 'react';
import './Splash.css';
import logo from '../assets/Arigatou Travel Support くん.jpg';
import backgroundImageMobile from '../assets/arcade-background.jpg';
import backgroundImageDesktop from '../assets/arcade-background-desktop.jpg';

const Splash = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [displayText, setDisplayText] = useState('');
  const message = 'ARIGATOU TRAVEL SUPPORT くん';
  const encryptedText = 'sR3leV90 mW9Slp R7uJmWc A9';

  useEffect(() => {
    let currentIndex = 0;
    const firstPart = encryptedText.split(' ')[0] + ' ';
    const secondPart = encryptedText.split(' ')[1] + ' ';
    const thirdPart = encryptedText.split(' ')[2];
    const fourthPart = ' ' + encryptedText.split(' ')[3];
    const decryptedFirstPart = message.split(' ')[0] + ' ';
    const decryptedSecondPart = message.split(' ')[1] + ' ';
    const decryptedThirdPart = message.split(' ')[2];
    const decryptedFourthPart = ' ' + message.split(' ')[3];

    const typingInterval = setInterval(() => {
      if (currentIndex <= firstPart.length) {
        setDisplayText(firstPart.slice(0, currentIndex));
        currentIndex++;
      } else if (currentIndex <= firstPart.length + decryptedFirstPart.length) {
        const decryptedIndex = currentIndex - firstPart.length - 1;
        const remainingEncryptedIndex = currentIndex - firstPart.length;
        setDisplayText(
          <span>
            <span className="decrypted-text">{decryptedFirstPart.slice(0, decryptedIndex + 1)}</span>
            {firstPart.slice(decryptedIndex + 1)}
            {secondPart.slice(0, remainingEncryptedIndex)}
          </span>
        );
        currentIndex++;
      } else if (currentIndex <= firstPart.length + decryptedFirstPart.length + decryptedSecondPart.length) {
        const decryptedIndex = currentIndex - firstPart.length - decryptedFirstPart.length - 1;
        const remainingEncryptedIndex = currentIndex - firstPart.length - decryptedFirstPart.length;
        setDisplayText(
          <span>
            <span className="decrypted-text">{decryptedFirstPart}</span>
            <span className="decrypted-text">{decryptedSecondPart.slice(0, decryptedIndex + 1)}</span>
            {secondPart.slice(decryptedIndex + 1)}
            {thirdPart.slice(0, remainingEncryptedIndex)}
          </span>
        );
        currentIndex++;
      } else if (currentIndex <= firstPart.length + decryptedFirstPart.length + decryptedSecondPart.length + decryptedThirdPart.length) {
        const decryptedIndex = currentIndex - firstPart.length - decryptedFirstPart.length - decryptedSecondPart.length - 1;
        const remainingEncryptedIndex = currentIndex - firstPart.length - decryptedFirstPart.length - decryptedSecondPart.length;
        setDisplayText(
          <span>
            <span className="decrypted-text">{decryptedFirstPart}</span>
            <span className="decrypted-text">{decryptedSecondPart}</span>
            <span className="decrypted-text">{decryptedThirdPart.slice(0, decryptedIndex + 1)}</span>
            {thirdPart.slice(decryptedIndex + 1)}
            {fourthPart.slice(0, remainingEncryptedIndex)}
          </span>
        );
        currentIndex++;
      } else if (currentIndex <= firstPart.length + decryptedFirstPart.length + decryptedSecondPart.length + decryptedThirdPart.length + decryptedFourthPart.length) {
        const decryptedIndex = currentIndex - firstPart.length - decryptedFirstPart.length - decryptedSecondPart.length - decryptedThirdPart.length - 1;
        setDisplayText(
          <span>
            <span className="decrypted-text">{decryptedFirstPart}</span>
            <span className="decrypted-text">{decryptedSecondPart}</span>
            <span className="decrypted-text">{decryptedThirdPart}</span>
            <span className="decrypted-text">{decryptedFourthPart.slice(0, decryptedIndex + 1)}</span>
            {fourthPart.slice(decryptedIndex + 1)}
          </span>
        );
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          setIsVisible(false);
        }, 1000);
      }
    }, 75);

    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div
          id="splash-screen"
          style={{
            backgroundImage: `url(${window.innerWidth >= 768 ? backgroundImageDesktop : backgroundImageMobile})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="splash-content">
            <p className="encrypted-text">{displayText}</p>
            <img src={logo} alt="Arigatou Travel Support-kun" />
          </div>
        </div>
      )}
    </>
  );
};

export default Splash;
