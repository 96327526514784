import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import './HeroSection.css';
import img1 from '../assets/IMG_9019.jpg';
import img2 from '../assets/IMG_9039.jpg';
import img3 from '../assets/IMG_9005.jpg';
import img4 from '../assets/IMG_9155.jpg';

function HeroSection() {
  const [title, setTitle] = useState('');
  const [index, setIndex] = useState(0);
  const [showArrow, setShowArrow] = useState(false);
  const [showTitleCursor, setShowTitleCursor] = useState(true);
  const [isSplashScreen, setIsSplashScreen] = useState(true);
  const originalTitle = "sX3il 2qNPO Vbsw7";
  const convertedTitle = "Your All-in-One";
  const remainingTitle = "aKD87 Ld12N OyKLb";
  const convertedRemainingTitle = "Online Travel ";
  const finalRemainingTitle = "QaEw7 9uIXl";
  const convertedFinalRemainingTitle = "Concierge ";
  const lastRemainingTitle = "P0jNi ";
  const convertedLastRemainingTitle = "for Japan";
  const images = [img1, img2, img3, img4];

  useEffect(() => {
    const splashTimer = setTimeout(() => {
      setIsSplashScreen(false);
    }, 2000); // スプラッシュスクリーンを2秒間表示
    return () => clearTimeout(splashTimer);
  }, []);

  useEffect(() => {
    if (!isSplashScreen) {
      const timer = setTimeout(() => {
        let titleIndex = 0;
        const typeTitle = () => {
          if (titleIndex < originalTitle.length) {
            setTitle(originalTitle.slice(0, titleIndex + 1));
            titleIndex++;
            setTimeout(typeTitle, 50);
          } else {
            setTimeout(() => {
              setShowTitleCursor(false);
              setTimeout(() => {
                convertTitle();
              }, 100);
            }, 100);
          }
        };

        const convertTitle = () => {
          let convertIndex = 0;
          let remainingIndex = 0;
          const convertTimer = setInterval(() => {
            if (convertIndex < convertedTitle.length) {
              setTitle(convertedTitle.slice(0, convertIndex + 1) + originalTitle.slice(convertIndex + 1, originalTitle.length) + remainingTitle.slice(0, remainingIndex + 1));
              convertIndex++;
              remainingIndex++;
            } else {
              clearInterval(convertTimer);
              setTimeout(() => {
                convertRemainingTitle();
              }, 100);
            }
          }, 50);
        };

        const convertRemainingTitle = () => {
          let convertIndex = 0;
          let finalRemainingIndex = 0;
          const convertTimer = setInterval(() => {
            if (convertIndex < convertedRemainingTitle.length) {
              setTitle(convertedTitle + " " + convertedRemainingTitle.slice(0, convertIndex + 1) + remainingTitle.slice(convertIndex + 1, remainingTitle.length) + finalRemainingTitle.slice(0, finalRemainingIndex + 1));
              convertIndex++;
              finalRemainingIndex++;
            } else {
              clearInterval(convertTimer);
              setTimeout(() => {
                convertFinalRemainingTitle();
              }, 100);
            }
          }, 50);
        };

        const convertFinalRemainingTitle = () => {
          let convertIndex = 0;
          let lastRemainingIndex = 0;
          const convertTimer = setInterval(() => {
            if (convertIndex < convertedFinalRemainingTitle.length) {
              setTitle(convertedTitle + " " + convertedRemainingTitle + convertedFinalRemainingTitle.slice(0, convertIndex + 1) + finalRemainingTitle.slice(convertIndex + 1, finalRemainingTitle.length) + lastRemainingTitle.slice(0, lastRemainingIndex + 1));
              convertIndex++;
              lastRemainingIndex++;
            } else {
              clearInterval(convertTimer);
              setTimeout(() => {
                convertLastRemainingTitle();
              }, 100);
            }
          }, 50);
        };

        const convertLastRemainingTitle = () => {
          let convertIndex = 0;
          const convertTimer = setInterval(() => {
            if (convertIndex < convertedLastRemainingTitle.length) {
              setTitle(convertedTitle + " " + convertedRemainingTitle + convertedFinalRemainingTitle + convertedLastRemainingTitle.slice(0, convertIndex + 1) + lastRemainingTitle.slice(convertIndex + 1, lastRemainingTitle.length));
              convertIndex++;
            } else {
              clearInterval(convertTimer);
              setTimeout(() => {
                setShowArrow(true);
              }, 100);
            }
          }, 50);
        };

        typeTitle();
      }, 1250);
      return () => clearTimeout(timer);
    }
  }, [isSplashScreen]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <section id="hero">
      {isSplashScreen ? (
        <div className="splash-screen">
          {/* スプラッシュスクリーンの内容を追加 */}
        </div>
      ) : (
        <>
          <div className="hero-slideshow">
            <img src={images[index]} alt={`Hero ${index + 1}`} className="hero-slide" />
          </div>
          <div className="hero-text">
            <h2>
              {title}
              {showTitleCursor && <span className="cursor">_</span>}
            </h2>
          </div>
          {showArrow && (
            <div className="arrow-animation">
              <div className="scroll-text">
                <FormattedMessage
                  id="hero.scroll"
                  defaultMessage="scroll"
                />
              </div>
              <div className="arrow-container">
                <div className="arrow"></div>
              </div>
            </div>
          )}
        </>
      )}
    </section>
  );
}

export default HeroSection;
