import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAZd7XqhgUVxrIh_HJrSwOkR3vzsgTJo-o",
  authDomain: "jasp24web.firebaseapp.com",
  projectId: "jasp24web",
  storageBucket: "jasp24web.appspot.com",
  messagingSenderId: "785375098855",
  appId: "1:785375098855:web:f48913efb454721e792f42",
  measurementId: "G-ZDPH8842SV"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

export { auth, db, googleProvider };
